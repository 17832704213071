import React from "react"
import Content from "./content"
import Title from "./title"

const Wrapper = ({ title, sections }) => {
  return (
    <div className="about-us-wrapper">
      <Title title={title} />
      <Content content={sections.find(s => s.key === "about-us-content")} />
    </div>
  )
}

export default Wrapper
