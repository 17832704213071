import React, { useContext } from "react"
import VizSensor from "react-visibility-sensor"

import Image from "../image"

import { ScrollContext } from "../layout"

import "./title.scss"

const Title = ({ title }) => {
  const [, setFirstBlockVisibility] = useContext(ScrollContext)
  const image = {
    fileName: "team_photo.png",
    alt: "Csapatfoto",
    style: { minHeight: "248px" },
  }
  return (
    <VizSensor
      onChange={isVisible => setFirstBlockVisibility(isVisible)}
      partialVisibility
    >
      <div id="about-us-title">
        <div>
          <h1>{title}</h1>
        </div>
        <Image fileName={image.fileName} alt={image.alt} style={image.style} />
      </div>
    </VizSensor>
  )
}

export default Title
