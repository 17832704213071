import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm';

import "./content.scss"

const Content = ({ content }) => {
  return (
    <div id="about-us-content">
      {content.blocks
        .sort((a, b) => a.order - b.order)
        .map((b, i) => (
          <div className="content-block" key={i}>
            <h3>{b.title}</h3>
            <div>
              {/* <ReactMarkdown source={b.content} /> */}
              <ReactMarkdown children={b.content} remarkPlugins={[remarkGfm]} />

            </div>
          </div>
        ))}
    </div>
  )
}

export default Content
