import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/common/contact"
import Relations from "../components/common/relations"
import Wrapper from "../components/about-us/wrapper"

const AboutUs = () => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        aboutUs {
          title
          sections {
            title
            key
            blocks {
              title
              content
              order
            }
          }
        }
      }
    }
  `)
  const { title, sections } = pages.aboutUs
  return (
    <Layout>
      <SEO title={title} />
      <Wrapper title={pages.aboutUs.title} sections={sections} />
      <Contact
        section={sections.find(s => s.key === "contact")}
        hidePicture={true}
      />
      <Relations section={sections.find(s => s.key === "relations")} />
    </Layout>
  )
}

export default AboutUs
